var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":"","id":"scroll-target"}},[(
      _vm.mode === 'cart' &&
      _vm.type === 'sale' &&
      ((_vm.getPermissions([`product.view_productiondetails`]) &&
        _vm.getPermissions([`sales.view_orderstatus`])) ||
        _vm.isAdmin)
    )?_c('dialprodsale',{ref:"dialprodsale",attrs:{"order":_vm.order,"customer":_vm.customer !== null ? _vm.customer.company_name : '',"orderName":_vm.orderName,"orderImage":_vm.orderImage,"status-order":_vm.statusOrder,"customerTax":_vm.customerTax}}):_vm._e(),(
      _vm.mode === 'cart' &&
      _vm.type !== 'sale' &&
      ((_vm.getPermissions([`product.view_productiondetails`]) &&
        _vm.getPermissions([`purchase.view_purchaseorderstatus`])) ||
        _vm.isAdmin)
    )?_c('dialprodpurchase',{ref:"dialprodpurchase",attrs:{"order":_vm.order,"infoSupplier":_vm.customer !== null ? _vm.customer.company : '',"orderName":_vm.orderName,"status-order":_vm.statusOrder,"type_infoSupplier":_vm.customer !== null ? _vm.customer.level.id : '',"infoSupplierTax":_vm.customerTax}}):_vm._e(),_c('v-tabs',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode == 'product'),expression:"mode == 'product'"}],staticClass:"mb-2",attrs:{"centered":"","color":"primary","next-icon":"fa-chevron-right","prev-icon":"fa-chevron-left","show-arrows":"","icons-and-text":_vm.$vuetify.breakpoint.smAndUp},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',{attrs:{"href":"#tab-active"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$t('active'))}}):_vm._e(),_c('v-icon',{attrs:{"medium":_vm.$vuetify.breakpoint.smAndUp,"dark":""}},[_vm._v(" fas fa-check-double ")])],1),_c('v-tab',{attrs:{"href":"#tab-inactive"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$t('inactive'))}}):_vm._e(),_c('v-icon',{attrs:{"medium":_vm.$vuetify.breakpoint.smAndUp,"dark":""}},[_vm._v(" fa-times-circle ")])],1),_c('v-tab',{attrs:{"href":"#tab-models"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"mt-2",domProps:{"textContent":_vm._s(_vm.$t('models'))}}):_vm._e(),_c('v-icon',{attrs:{"medium":_vm.$vuetify.breakpoint.smAndUp,"dark":""}},[_vm._v("fa-clone")])],1)],1),_c('v-card',[_c('i-card-list',{staticClass:"mt-2 px-2",attrs:{"api":"product","app":"product","dontHeader":"","dontRemove":"","simple":"","method":_vm.mode == 'product'
          ? 'list'
          : _vm.type == 'sale'
          ? 'salecart'
          : 'purchasecart',"mode":_vm.mode,"opt":{
        company_id: this.tabs == 'tab-models' ? null : this.company.pk,
        template: this.template,
        active: _vm.tabStatus(this.tabs),
        model: this.tabs == 'tab-models' ? true : false
      },"reload":_vm.reload,"title":_vm.$t('product_catalog')},on:{"update:reload":function($event){_vm.reload=$event},"click:create":function($event){return _vm.changeRoute({ pk: 'create' })},"click:edit":function($event){return _vm.validateIsAdmin($event)}},scopedSlots:_vm._u([{key:`item`,fn:function({ item, showMode, remove }){return [_c('v-card-text',[_c('v-row',{staticClass:"my-0",class:{
              unableProd: !item.active
            }},[(showMode !== 'simple')?_c('v-col',{staticClass:"d-flex justify-content-center position-relative",attrs:{"cols":showMode === 'grid' ? 12 : 2}},[_c('i-image',{staticClass:"product-image",style:({
                  width: '10em !important',
                  height: '8em !important'
                }),attrs:{"value":item.image,"preview":true,"readonly":true}}),(showMode === 'grid')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":"red","absolute":"","icon":"","right":"","small":"","top":""},on:{"click":function($event){$event.stopPropagation();return _vm.changeFavorite(item)}}},on),[_c('v-icon',{attrs:{"small":"","color":"red lighten-1"},domProps:{"textContent":_vm._s(item.favorite ? 'fas fa-heart' : 'far fa-heart')}})],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.favorite ? _vm.$tc('leave', 1) : _vm.$t('add2', { value: _vm.$t('favorite') }))+" ")]):_vm._e()],1):_vm._e(),_c('v-col',{staticClass:"d-flex flex-column justify-content-center pt-0 g-1",attrs:{"cols":"12","md":showMode != 'grid' ? 2 : 12}},[_c('p',{staticClass:"primary--text font-weight-bold mb-0"},[_vm._v(" "+_vm._s(`${item.internalCode}`)+" ")]),_c('p',{staticClass:"primary--text font-weight-black mb-0",class:{
                  title: showMode === 'grid' ? true : false,
                  'subtitle-1': showMode === 'grid' ? false : true
                }},[_vm._v(" "+_vm._s(`${item.name}`)+" ")]),(item.can_be != 'SALE')?_c('p',{staticClass:"grey--text font-weight-bold mb-0 caption"},[_vm._v(" "+_vm._s(`${_vm.$tc('stock', 1)}: `)+" "),_c('span',{class:item.quantity - item.min_stock > 0
                      ? 'primary--text'
                      : 'error--text'},[_vm._v(" "+_vm._s(`${item.quantity}`)+" ")])]):_vm._e(),(item.can_be != 'SALE')?_c('p',{staticClass:"grey--text font-weight-bold mb-0 caption"},[_vm._v(" "+_vm._s(`${_vm.$tc('minStock', 1)}: `)+" "),_c('span',{class:item.quantity - item.min_stock > 0
                      ? 'primary--text'
                      : 'error--text'},[_vm._v(" "+_vm._s(`${item.min_stock}`)+" ")])]):_vm._e()]),_c('v-col',{attrs:{"cols":"12","md":showMode == 'simple' ? 7 : showMode == 'list' ? 5 : 12}},[(item.template !== null)?_c('p',{staticClass:"grey--text mb-1"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(`${_vm.$tc('template', 1)}: `)+" ")]),_c('span',{staticClass:"primary--text subtitle-2"},[_vm._v(" "+_vm._s(item.template_name)+" ")])]):_vm._e(),_c('p',{staticClass:"mt-0 grey--text font-weight-black subtitle-2"},[_vm._v(" "+_vm._s(`${_vm.$tc(_vm.inventoriable(item.can_be))}`)+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode == 'product'),expression:"mode == 'product'"}],attrs:{"cols":"12","sm":showMode == 'simple' ? 8 : 12,"md":showMode == 'list' ? 7 : showMode == 'simple' ? 3 : 12}},[(item.price_type === 'matrix')?_c('span',{staticClass:"primary--text subtitle-2 font-weight-black"},[_vm._v(" "+_vm._s(_vm.$t('matrix_prices'))+" ")]):_vm._e(),(item.price_type !== 'matrix')?_c('v-list',{staticClass:"product-index-customer-types",attrs:{"dense":""}},[(
                      _vm.getPermissions([`product.view_productprices`]) ||
                      _vm.isAdmin
                    )?_c('v-list-group',{on:{"click":function($event){$event.stopPropagation();return _vm.getPrices(item.pk)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_c('span',{staticClass:"primary--text subtitle-2 font-weight-black"},[_vm._v(" "+_vm._s(_vm.$tc('level', 2))+" ")])])]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"slot":"appendIcon","size":"12"},slot:"appendIcon"},[_vm._v(" fas fa-arrow-down ")]),(_vm.loadPrice)?_c('v-skeleton-loader',{attrs:{"type":"image","tile":""}}):_vm._e(),_vm._l((_vm.prices[item.pk]),function(param){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.prices[item.pk] != undefined),expression:"prices[item.pk] != undefined"}],key:'param-' + param.pk,attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"pl-1 primary--text",staticStyle:{"font-size":"12px","font-weight":"400"}},[_c('b',[_vm._v("·")]),_c('i-money',{attrs:{"mode":"text","value":param.price}},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(`${param.name}:`)+" ")])])],1)],1)],1)})],2):_vm._e()],1):_vm._e()],1)]),_c('v-col',{staticClass:"d-flex flex-column justify-content-center g-1 grey--text",attrs:{"cols":"12","md":showMode != 'grid' ? 3 : 12}},[_c('p',{staticClass:"primary--text caption text-right mb-2"},[(
                    _vm.mode == 'product' &&
                    (_vm.viewOtherBtn.add || _vm.viewOtherBtn.delete)
                  )?_c('v-menu',{staticClass:"ml-0",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"pr-0",attrs:{"color":"primary","dark":"","small":"","text":""},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" "+_vm._s(_vm.$t('action'))+" "),_c('v-icon',{staticClass:"ml-1 mr-3",attrs:{"size":"10"}},[_vm._v(" fa-chevron-down ")])],1)]}}],null,true)},[_c('v-list',[(_vm.viewOtherBtn.add)?_c('v-list-item',[_c('i-btn',{attrs:{"classes":"mx-1","color":"primary","icon":"fa-clone","outlined":"","title":_vm.$tc('clone', 1)},on:{"click":function($event){$event.preventDefault();return _vm.clone(item.pk)}}})],1):_vm._e(),(_vm.canRemove(item, _vm.viewOtherBtn, _vm.tabs))?_c('v-list-item',[_c('i-btn',{attrs:{"classes":"mx-1","color":"error","icon":"fa-trash","outlined":"","title":_vm.$tc('delete', 1)},on:{"click":function($event){$event.preventDefault();return remove(item.pk)}}})],1):_vm._e()],1)],1):_vm._e(),(showMode !== 'grid')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.viewOtherBtn.edit)?_c('v-btn',_vm._g({attrs:{"small":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.changeFavorite(item)}}},on),[_c('v-icon',{attrs:{"small":"","color":"red lighten-1"},domProps:{"textContent":_vm._s(
                          item.favorite ? 'fas fa-heart' : 'far fa-heart'
                        )}})],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.favorite ? _vm.$tc('leave', 1) : _vm.$t('add2', { value: _vm.$t('favorite') }))+" ")])]):_vm._e()],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode == 'product' && item.price_type !== 'matrix'),expression:"mode == 'product' && item.price_type !== 'matrix'"}],staticClass:"text-right mb-1"},[_c('span',{staticClass:"primary--text font-weight-black"},[_vm._v(" "+_vm._s(`${_vm.$tc('cost', 1)}: `)+" ")]),_c('i-money',{staticClass:"primary--text",attrs:{"mode":"text","value":item.cost}})],1),(
                  _vm.mode == 'cart' &&
                  _vm.type == 'sale' &&
                  item.price_type !== 'matrix'
                )?_c('p',{staticClass:"text-right"},[_c('showProductPrice',{attrs:{"pkProduct":item.pk,"priceSuggest":item.priceSuggest,"type_customer":_vm.customer !== null ? _vm.customer.level.id : '',"customer":_vm.customer !== null ? _vm.customer.pk : ''}})],1):_vm._e(),(
                  _vm.mode == 'cart' &&
                  _vm.type == 'sale' &&
                  item.price_type == 'matrix'
                )?_c('p',{staticClass:"text-right"},[_c('span',{staticClass:"primary--text font-weight-bold headline"},[_vm._v(" "+_vm._s(_vm.$t('matrix_prices'))+" ")])]):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode == 'cart'),expression:"mode == 'cart'"}],staticClass:"grey--text caption text-right mb-2"},[_vm._v(" "+_vm._s(`${_vm.$t('turnaround')}: ${item.turnaround}`)+" ")])])],1)],1)]}}],null,true)}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.getters.getIsloading),expression:"!$store.getters.getIsloading"},{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],attrs:{"id":"go-to","bottom":"","color":"secondary","dark":"","fab":"","fixed":"","right":""},on:{"click":function($event){return _vm.$vuetify.goTo(_vm.positionScroll ? 0 : '#pagination')}}},[_c('v-icon',{attrs:{"dark":""},domProps:{"textContent":_vm._s(_vm.positionScroll ? 'fa-chevron-up' : 'fa-chevron-down')}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }