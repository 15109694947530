<template>
  <v-dialog
    v-model="dialog"
    class="standard-size"
    width="97vw"
    persistent
    fullscreen
  >
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent="handleSubmit()">
          <i-toolbar
            dark
            color="secondary"
            dontSave
            :title="`${subOrder.own_prod}`"
            :loading="loading"
          >
            <template v-slot:close>
              <i-btn
                text
                :small="$vuetify.breakpoint.smAndDown"
                icon="fa-times"
                :loading="load"
                @click="close()"
                :title="$t('close')"
              >
              </i-btn>
            </template>
            <template v-slot:arrowLeft>
              <i-btn
                text
                v-if="step > 1 && creatingDetail === 1"
                :small="$vuetify.breakpoint.smAndDown"
                icon="fa-arrow-left"
                color="text-dark"
                @click="step -= 1"
                :title="$t('back')"
              >
              </i-btn>
            </template>
            <template v-slot:arrowRight>
              <i-btn
                text
                v-if="step == 1 && creatingDetail === 1"
                :small="$vuetify.breakpoint.smAndDown"
                icon="fa-arrow-right"
                color="text-dark"
                @click="next()"
                :title="$t('next')"
              >
              </i-btn>
            </template>
            <template v-slot:submit>
              <i-btn
                text
                v-if="subOrder.pk !== '' && creatingDetail === 1"
                :small="$vuetify.breakpoint.smAndDown"
                icon="fa-save"
                @click="submit"
                type="submit"
                :title="$tc('save', 1)"
              >
              </i-btn>
            </template>
          </i-toolbar>
          <v-card-text class="px-0">
            <v-stepper style="z-index: 0" class="elevation-0" v-model="step">
              <v-stepper-header class="elevation-0">
                <v-stepper-step :step="1" edit-icon="$complete" editable>{{
                  $t('basic')
                }}</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  :step="2"
                  edit-icon="$complete"
                  :editable="subOrder.pk !== ''"
                >
                  {{ $tc('product', 2) }}
                </v-stepper-step>
                <v-divider></v-divider>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content :step="1">
                  <br />
                  <v-row>
                    <v-col
                      :class="{
                        'align-center': true,
                        'justify-center': true,
                        'align-self-center': true,
                        'px-3': $vuetify.breakpoint.smAndDown,
                        'px-5': $vuetify.breakpoint.smAndUp,
                        'py-0': true,
                        'my-0': true
                      }"
                      cols="12"
                      sm="5"
                    >
                      <v-skeleton-loader
                        v-if="loading || loadingIMG"
                        type="image"
                        tile
                      ></v-skeleton-loader>
                      <i-image
                        v-else
                        v-model="imageUrl"
                        :readonly="
                          subOrder.orderMain == 'canceled' ||
                          subOrder.orderMain == 'finished'
                        "
                        :file.sync="subOrder.thumbnail"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-row>
                        <br />
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          v-if="subOrder.code !== ''"
                        >
                          <v-skeleton-loader
                            v-if="loading"
                            type="text"
                            tile
                          ></v-skeleton-loader>
                          <ValidationProvider
                            vid="code"
                            :name="$tc('code', 1)"
                            rules=""
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              outlined
                              v-show="!loading"
                              disabled
                              :error-messages="errors[0]"
                              v-model="subOrder.code"
                              color="secondary"
                              :label="$tc('subname', 1)"
                              key="code-input"
                            >
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          v-if="subOrder !== undefined && subOrder !== null"
                          :md="
                            subOrder.orderMain === 'in_process' ||
                            subOrder.orderMain === 'finished'
                              ? 4
                              : 6
                          "
                        >
                          <v-skeleton-loader
                            v-if="loading"
                            type="text"
                            tile
                          ></v-skeleton-loader>
                          <ValidationProvider
                            vid="name"
                            :name="$tc('name', 1)"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              outlined
                              v-show="!loading"
                              :disabled="
                                !(
                                  subOrder.orderMain == 'draft' ||
                                  subOrder.orderMain == 'pdocument'
                                )
                              "
                              :error-messages="errors[0]"
                              v-model="subOrder.name"
                              color="secondary"
                              :label="$tc('subname', 1)"
                              key="name-input"
                            >
                              <div
                                slot="prepend"
                                v-if="
                                  projectName !== '' &&
                                  projectName !== null &&
                                  projectName !== undefined
                                "
                              >
                                <v-icon
                                  medium
                                  @click="cloneNameProject()"
                                  color="info"
                                  v-text="'fa-clone'"
                                />
                              </div>
                            </v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col
                          v-show="
                            getPermissions([`supplier.view_supplier`]) ||
                            isAdmin
                          "
                          cols="12"
                          sm="6"
                          :md="
                            subOrder.orderMain === 'in_process' ||
                            subOrder.orderMain === 'finished'
                              ? 4
                              : 6
                          "
                        >
                          <v-skeleton-loader
                            v-if="loading"
                            type="text"
                            tile
                          ></v-skeleton-loader>
                          <v-text-field
                            outlined
                            v-show="!loading"
                            name="infoSupplier"
                            v-model="subOrder.infoSupplierName"
                            disabled
                            color="secondary"
                            :label="$tc('supplier', 1)"
                            key="infoSupplier-input"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          v-if="
                            subOrder.orderMain === 'in_process' ||
                            subOrder.orderMain === 'finished'
                          "
                        >
                          <v-skeleton-loader
                            v-if="loading"
                            type="list-item-avatar"
                            tile
                          ></v-skeleton-loader>
                          <ValidationProvider
                            vid="state"
                            :name="$tc('state', 1)"
                            rules=""
                            v-slot="{ errors }"
                          >
                            <v-select
                              outlined
                              v-show="!loading"
                              :items="stages"
                              v-model="subOrder.status"
                              item-value="pk"
                              :disabled="subOrder.orderMain === 'finished'"
                              :item-text="getNameStatus"
                              outline
                              :error-messages="errors[0]"
                              color="primary"
                              :label="$t('state')"
                            ></v-select>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-skeleton-loader
                        v-if="loading"
                        type="article"
                        tile
                      ></v-skeleton-loader>
                      <ValidationProvider
                        vid="description"
                        :name="$tc('description', 1)"
                        rules=""
                        v-slot="{ errors }"
                      >
                        <label v-if="!loading" for="description">{{
                          $t('description')
                        }}</label>
                        <vue-editor
                          v-if="!loading"
                          id="description"
                          name="description"
                          v-model="subOrder.description"
                          :disabled="
                            subOrder.orderMain === 'in_process' ||
                            subOrder.orderMain === 'finished'
                          "
                          :readonly="
                            subOrder.orderMain === 'in_process' ||
                            subOrder.orderMain === 'finished'
                          "
                          :editorToolbar="customToolbar"
                          :error-messages="errors[0]"
                        >
                        </vue-editor>
                        <span
                          class="areaErrors"
                          v-if="errors[0] != '' && errors[0] != null"
                        >
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-skeleton-loader
                        v-if="loading"
                        type="article"
                        tile
                      ></v-skeleton-loader>
                      <ValidationProvider
                        vid="additional_side"
                        :name="$tc('additional_side', 1)"
                        rules=""
                        v-slot="{ errors }"
                      >
                        <label v-if="!loading" for="additional_side">{{
                          $t('additional_side')
                        }}</label>
                        <vue-editor
                          v-if="!loading"
                          id="additional_side"
                          v-model="subOrder.additional_side"
                          :disabled="
                            subOrder.orderMain === 'in_process' ||
                            subOrder.orderMain === 'finished'
                          "
                          :readonly="
                            subOrder.orderMain === 'in_process' ||
                            subOrder.orderMain === 'finished'
                          "
                          :editorToolbar="customToolbar"
                          :error-messages="errors[0]"
                        >
                        </vue-editor>
                        <span
                          class="areaErrors"
                          v-if="errors[0] != '' && errors[0] != null"
                        >
                          {{ errors[0] }}
                        </span>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content :step="2">
                  <br />
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-skeleton-loader
                        v-if="loading"
                        type="list-item-avatar"
                        tile
                      ></v-skeleton-loader>
                      <ValidationProvider
                        vid="measure"
                        :name="$tc('measure', 1)"
                        :rules="{ required: subOrder.pk !== '' }"
                        v-slot="{ errors }"
                      >
                        <v-select
                          outlined
                          :disabled="
                            !(
                              subOrder.orderMain == 'draft' ||
                              subOrder.orderMain == 'pdocument'
                            ) || !getPermissions([`base_config.change_soldby`])
                          "
                          v-show="!loading"
                          :items="measures"
                          v-model="subOrder.measure"
                          item-value="pk"
                          item-text="name"
                          outline
                          :error-messages="errors[0]"
                          color="primary"
                          :label="$t('soldby')"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <ValidationProvider
                        vid="amount"
                        :name="$tc('amount', 1)"
                        rules="required|double:2"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          outlined
                          :disabled="
                            !(
                              subOrder.orderMain == 'draft' ||
                              subOrder.orderMain == 'pdocument'
                            )
                          "
                          :error-messages="errors[0]"
                          type="tel"
                          v-model="subOrder.quantity"
                          color="secondary"
                          :label="$tc('amount', 1)"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <ValidationProvider
                        vid="price"
                        :name="$tc('price', 1)"
                        rules="required|double:2"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          :disabled="
                            !(
                              subOrder.orderMain == 'draft' ||
                              subOrder.orderMain == 'pdocument'
                            )
                          "
                          outlined
                          :error-messages="errors[0]"
                          type="tel"
                          v-model="subOrder.price"
                          color="secondary"
                          :label="$tc('price', 1)"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
          <v-card-actions
            class="white"
            style="width: 100%; position: fixed; bottom: 0; right: 0"
          >
            <v-row v-if="step === 2" no-gutters>
              <v-col cols="12" sm="8" style="padding-right: 0px">
                <v-row class="mx-0 px-0 moneyContainer">
                  <v-col
                    :class="{ 'py-1': $vuetify.breakpoint.xsOnly }"
                    col="12"
                    md="4"
                    lg="3"
                  >
                    <span class="white--text descriptiveMoney">
                      {{ $tc('product', 1) }}:
                    </span>
                    <i-money
                      mode="text"
                      :value="showprice"
                      class="white--text descriptiveMoney"
                    />
                  </v-col>
                  <v-col
                    :class="{ 'py-1': $vuetify.breakpoint.xsOnly }"
                    col="12"
                    md="4"
                    lg="3"
                  >
                    <span class="white--text descriptiveMoney">
                      {{ $tc('tax', 1) }}:
                    </span>
                    <i-money
                      mode="text"
                      :value="subOrder.tax"
                      class="white--text descriptiveMoney"
                    />
                  </v-col>
                  <v-col
                    :class="{ 'py-1': $vuetify.breakpoint.xsOnly }"
                    col="12"
                    md="4"
                    lg="3"
                  >
                    <span class="white--text descriptiveMoney">
                      {{ $tc('subtotal', 1) }}:
                    </span>
                    <i-money
                      mode="text"
                      :value="subOrder.subtotal"
                      class="white--text descriptiveMoney"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="4" style="display: flex; padding-left: 0px">
                <v-row
                  style="
                    margin-right: 0px !important;
                    margin-left: 0px !important;
                  "
                >
                  <v-col
                    col="12"
                    class="py-0"
                    style="
                      border-radius: 0px 20px 20px 0px;
                      border: 1px solid #34495e;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                    "
                  >
                    <span class="primary--text totalMoney">Total:</span>
                    <i-money
                      mode="text"
                      :value="total"
                      class="secondary--text totalMoney pl-1"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>
        </form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'
export default {
  components: {
    VueEditor
  },
  mounted() {
    this.getTax()
  },
  data() {
    return {
      loadingIMG: false,
      imageUrl: null,
      oldStatus: null,
      oldStatusName: null,
      panel: [],
      detailPrices: [],
      creatingDetail: 1,
      projectName: this.orderName,
      taxes: [],
      subOrder: {
        pk: '',
        name: '',
        order: '',
        status: null,
        product: null,
        dimensions: {},
        price: 0,
        description: '',
        additional_side: '',
        quantity: 1,
        subtotal: 0,
        total: 0,
        tax: 0,
        documentSelect: '',
        own_prod: '',
        orderStatus: '',
        orderMain: '',
        measure: null,
        template: null,
        infoSupplierName: '',
        infoSupplierEmail: '',
        thumbnail: null,
        received_quantity: 0,
        pending_quantity: 1
      },
      dialog: false,
      load: false,
      step: 1,
      loading: false,
      menu: false,
      menu1: false,
      menu2: false,
      measures: [],
      measure: null,
      stages: [],
      stage: null,
      details: [],
      customToolbar: [
        [{ header: [] }],
        [
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'code',
          'code-block'
        ],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }]
      ]
    }
  },
  props: {
    order: {
      type: [Number, String]
    },
    statusOrder: {
      type: String
    },
    infoSupplier: {
      type: String
    },
    infoSupplierEmail: {
      type: String
    },
    type_infoSupplier: {
      type: [Number, String]
    },
    infoSupplierTax: {
      default: false
    },
    orderName: {
      type: [String]
    }
  },
  methods: {
    cloneNameProject() {
      this.subOrder.name = this.projectName
    },
    getNameStatus: (item) =>
      item.dad === undefined || item.dad === null
        ? item.name
        : `${item.dad.name} - ${item.name}`,
    async getOrderStatus() {
      let response = await this.$api.purchase.status.list({
        opt: {
          params: {
            template: this.subOrder.template
          }
        }
      })
      let sta = []
      for (var value in response.data) {
        if (response.data[value].substatusVals.length > 0) {
          sta = sta.concat(response.data[value].substatusVals)
        } else {
          sta.push(response.data[value])
        }
      }
      this.stages = sta
    },
    async getProductDetails() {
      const response = await this.$api.product.details.show({
        pk: this.subOrder.product,
        opt: {
          params: {
            product: true,
            type: 'all'
          }
        }
      })
      this.details = response.data
    },
    async getProductSold() {
      const response = await this.$api.product.measure.show({
        pk: this.subOrder.product,
        opt: {
          params: {
            product: true
          }
        }
      })
      this.measures = response.data
      if (this.subOrder.measure === null && this.measures.length > 0) {
        let defaultMel = this.measures.find((m) => m.default === true)
        if (defaultMel === undefined) {
          defaultMel = this.measures[0]
        }
        this.subOrder.measure = defaultMel.pk
        this.measure = defaultMel
      } else if (this.subOrder.measure !== null && this.measures.length > 0) {
        let defaultMel = this.measures.find(
          (m) => m.pk === this.subOrder.measure
        )
        this.measure = defaultMel !== undefined ? defaultMel : null
      }
    },
    async getTax() {
      let response = await this.$api.tax.list({
        opt: {
          params: {
            company: this.company.pk
          }
        }
      })
      this.taxes = response.data
    },
    async open(item) {
      this.step = 1
      this.menu = false
      this.menu1 = false
      this.menu2 = false
      this.creatingDetail = 1
      this.projectName = this.orderName
      this.dialog = true
      let prod = {}
      if (typeof item !== 'object') {
        try {
          this.loading = true
          let response = await this.$api.purchase.product.show({
            pk: item
          })
          prod = response.data
          this.$route.meta.title = this.$t('edit', { model: '' })
        } catch (err) {
          this.dialog = false
        } finally {
          this.loading = false
        }
      }
      if (
        this.projectName === undefined &&
        prod.projectName !== '' &&
        prod.projectName !== undefined
      ) {
        this.projectName = prod.projectName
      }
      if (prod.dimensions === '') {
        prod.dimensions = {}
      }
      this.subOrder =
        typeof item === 'object'
          ? {
              pk: '',
              name: '',
              code: '',
              order: this.order,
              status: null,
              product: item.pk,
              measure: null,
              price: 0,
              dimensions: {},
              quantity: 1,
              subtotal: 0,
              description: '',
              additional_side: '',
              documentSelect: '',
              rounded: false,
              thumbnail: null,
              tax: 0,
              total: 0,
              own_prod: `${item.internalCode} - ${item.name}`,
              template: item.template_id,
              orderStatus: '',
              orderMain: this.statusOrder,
              costprod: item.cost,
              infoSupplierName: this.infoSupplier,
              infoSupplierEmail: this.infoSupplierEmail,
              infoSupplierTax: this.infoSupplierTax,
              productTax: item.tax,
              received_quantity: 0,
              pending_quantity: 1
            }
          : prod
      this.oldStatus = this.subOrder.status
      this.oldStatusName = this.subOrder.orderStatus
      this.getOrderStatus()
      this.getProductSold()
      this.getProductDetails()
      try {
        this.imageUrl = this.subOrder.thumbnail
        this.loadingIMG = false
      } catch (error) {
        this.imageUrl = require('@/assets/default_image.png')
        this.loadingIMG = false
      }
    },
    next() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          if (this.step == 1 && this.subOrder.pk == '') {
            this.submit()
          } else {
            this.step += 1
          }
        }
      })
    },
    async submit() {
      let form_data = new FormData()
      for (var key in this.subOrder) {
        if (this.subOrder[key] == null) {
          continue
        }
        form_data.append(
          key,
          key !== 'dimensions'
            ? this.subOrder[key]
            : JSON.stringify(this.subOrder[key])
        )

        if (
          key == 'thumbnail' &&
          (this.subOrder.thumbnail == null ||
            String(this.imageUrl).indexOf('http') != -1 ||
            String(this.subOrder.thumbnail).indexOf(';base64') != -1 ||
            String(this.subOrder.thumbnail).indexOf('http') != -1 ||
            String(this.imageUrl).indexOf('/sources/') != -1)
        ) {
          form_data.delete('thumbnail')
        }
      }
      try {
        this.load = true
        const response =
          this.subOrder.pk === '' || this.subOrder.pk === undefined
            ? await this.$api.purchase.product.create({
                form: form_data
              })
            : await this.$api.purchase.product.edit({
                pk: this.subOrder.pk,
                form: form_data
              })
        if (this.subOrder.pk == '' && this.step == 1) {
          this.step = 2
          this.subOrder = response.data
        } else if (this.subOrder.pk != '') {
          this.$store.dispatch('utils/createdsuborder', true)
          this.$emit('prod', response.data)
          this.close()
          this.$toast.success(
            `${this.$tc('suborder', 1)} ${this.$tc(
              this.subOrder.pk !== undefined ? 'edited' : 'created',
              2
            )}`
          )
        }
        if (this.subOrder.pending_quantity != this.subOrder.quantity) {
          this.subOrder.pending_quantity = this.subOrder.quantity
          await this.$api.purchase.product.edit({
            pk: this.subOrder.pk,
            form: this.subOrder
          })
        }
      } finally {
        this.load = false
      }
    },
    close() {
      if (this.$refs.imgAppr !== undefined) {
        this.$refs.imgAppr.clearForm()
      }
      this.dialog = false
      document.oncontextmenu = function () {
        return true
      }
    }
  },
  watch: {
    total: {
      handler(val) {
        this.subOrder.total = val
      }
    },
    subtotal: {
      handler(val) {
        this.subOrder.subtotal = val
        if (this.subOrder.infoSupplierTax && this.subOrder.productTax) {
          this.subOrder.tax = this.taxes
            .reduce(
              (accumulator, currentValue) =>
                accumulator +
                (Number(currentValue.percent) * Number(val)) / 100,
              0
            )
            .toFixed(2)
        }
      }
    },
    'subOrder.measure': {
      handler() {
        if (this.subOrder.measure !== null) {
          this.measure = this.measures.find(
            (m) => m.pk === this.subOrder.measure
          )
          if (
            typeof this.measure === 'object' &&
            this.measure.rounded === false
          ) {
            this.subOrder.rounded = false
          }
        }
      }
    },
    'subOrder.status': {
      handler() {
        if (this.subOrder.status !== null) {
          this.stage = this.stages.find((m) => m.pk === this.subOrder.status)
          this.subOrder.orderStatus =
            this.stage === undefined ? '' : this.stage.name
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData',
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    defaultmeasure() {
      let defaultMel = this.measures.find((m) => m.default === true)
      if (defaultMel === undefined) {
        defaultMel = this.measures[0]
      }
      defaultMel =
        defaultMel === undefined
          ? ''
          : defaultMel.name.split('(')[1].replace(')', '')
      return defaultMel
    },
    showprice() {
      let price = 0
      price += Number(this.subOrder.price)
      return price.toFixed(2)
    },
    subtotal() {
      return (
        (Number(this.subOrder.price) +
          Number(
            this.detailPrices.reduce(
              (accumulator, currentValue) =>
                accumulator + Number(currentValue.price),
              0
            )
          )) *
        this.subOrder.quantity
      ).toFixed(2)
    },
    total() {
      return (
        Number(this.subOrder.subtotal) + Number(this.subOrder.tax)
      ).toFixed(2)
    }
  }
}
</script>
<style>
.totalMoney {
  font-weight: bold;
  font-size: xx-large;
}

.descriptiveMoney {
  font-weight: bold;
  font-size: larger;
}

.moneyContainer {
  background-color: #345d5d;
  color: white !important;
  border-radius: 20px 0px 0px 20px;
}

@media screen and (max-width: 600px) {
  .totalMoney {
    font-size: larger;
  }

  .descriptiveMoney {
    font-size: medium;
  }

  .moneyContainer {
    background-color: #345d5d;
    border-radius: 0px 20px 20px 0px;
  }
}
</style>
