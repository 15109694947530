<route>
{
  "meta": {
    "permission": [
      "product.view_product"
    ]
  }
}
</route>

<template>
  <v-container fluid id="scroll-target">
    <dialprodsale
      v-if="
        mode === 'cart' &&
        type === 'sale' &&
        ((getPermissions([`product.view_productiondetails`]) &&
          getPermissions([`sales.view_orderstatus`])) ||
          isAdmin)
      "
      :order="order"
      :customer="customer !== null ? customer.company_name : ''"
      :orderName="orderName"
      :orderImage="orderImage"
      :status-order="statusOrder"
      :customerTax="customerTax"
      ref="dialprodsale"
    />
    <dialprodpurchase
      v-if="
        mode === 'cart' &&
        type !== 'sale' &&
        ((getPermissions([`product.view_productiondetails`]) &&
          getPermissions([`purchase.view_purchaseorderstatus`])) ||
          isAdmin)
      "
      :order="order"
      :infoSupplier="customer !== null ? customer.company : ''"
      :orderName="orderName"
      :status-order="statusOrder"
      :type_infoSupplier="customer !== null ? customer.level.id : ''"
      :infoSupplierTax="customerTax"
      ref="dialprodpurchase"
    />
    <v-tabs
      v-show="mode == 'product'"
      class="mb-2"
      centered
      color="primary"
      next-icon="fa-chevron-right"
      prev-icon="fa-chevron-left"
      show-arrows
      :icons-and-text="$vuetify.breakpoint.smAndUp"
      v-model="tabs"
    >
      <v-tab href="#tab-active">
        <span
          v-if="$vuetify.breakpoint.smAndUp"
          class="mt-2"
          v-text="$t('active')"
        ></span>
        <v-icon :medium="$vuetify.breakpoint.smAndUp" dark>
          fas fa-check-double
        </v-icon>
      </v-tab>
      <v-tab href="#tab-inactive">
        <span
          v-if="$vuetify.breakpoint.smAndUp"
          class="mt-2"
          v-text="$t('inactive')"
        ></span>
        <v-icon :medium="$vuetify.breakpoint.smAndUp" dark>
          fa-times-circle
        </v-icon>
      </v-tab>
      <v-tab href="#tab-models">
        <span
          v-if="$vuetify.breakpoint.smAndUp"
          class="mt-2"
          v-text="$t('models')"
        ></span>
        <v-icon :medium="$vuetify.breakpoint.smAndUp" dark>fa-clone</v-icon>
      </v-tab>
    </v-tabs>
    <v-card>
      <i-card-list
        api="product"
        app="product"
        class="mt-2 px-2"
        dontHeader
        dontRemove
        simple
        :method="
          mode == 'product'
            ? 'list'
            : type == 'sale'
            ? 'salecart'
            : 'purchasecart'
        "
        :mode="mode"
        :opt="{
          company_id: this.tabs == 'tab-models' ? null : this.company.pk,
          template: this.template,
          active: tabStatus(this.tabs),
          model: this.tabs == 'tab-models' ? true : false
        }"
        :reload.sync="reload"
        :title="$t('product_catalog')"
        @click:create="changeRoute({ pk: 'create' })"
        @click:edit="validateIsAdmin($event)"
      >
        <template v-slot:[`item`]="{ item, showMode, remove }">
          <v-card-text>
            <v-row
              class="my-0"
              :class="{
                unableProd: !item.active
              }"
            >
              <!-- Imagen: (solo si showMode es grid o list) -->
              <v-col
                v-if="showMode !== 'simple'"
                class="d-flex justify-content-center position-relative"
                :cols="showMode === 'grid' ? 12 : 2"
              >
                <i-image
                  class="product-image"
                  :style="{
                    width: '10em !important',
                    height: '8em !important'
                  }"
                  :value="item.image"
                  :preview="true"
                  :readonly="true"
                />
                <!-- Botón favoritos / Modo Grid -->
                <v-tooltip v-if="showMode === 'grid'" top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="red"
                      absolute
                      icon
                      right
                      small
                      top
                      @click.stop="changeFavorite(item)"
                    >
                      <v-icon
                        small
                        color="red lighten-1"
                        v-text="item.favorite ? 'fas fa-heart' : 'far fa-heart'"
                      />
                    </v-btn>
                  </template>
                  {{
                    item.favorite
                      ? $tc('leave', 1)
                      : $t('add2', { value: $t('favorite') })
                  }}
                </v-tooltip>
              </v-col>

              <!-- Código, Título, Stock disponible, Existencia -->
              <v-col
                class="d-flex flex-column justify-content-center pt-0 g-1"
                cols="12"
                :md="showMode != 'grid' ? 2 : 12"
              >
                <p class="primary--text font-weight-bold mb-0">
                  {{ `${item.internalCode}` }}
                </p>
                <p
                  class="primary--text font-weight-black mb-0"
                  :class="{
                    title: showMode === 'grid' ? true : false,
                    'subtitle-1': showMode === 'grid' ? false : true
                  }"
                >
                  {{ `${item.name}` }}
                </p>
                <p
                  v-if="item.can_be != 'SALE'"
                  class="grey--text font-weight-bold mb-0 caption"
                >
                  {{ `${$tc('stock', 1)}: ` }}
                  <span
                    :class="
                      item.quantity - item.min_stock > 0
                        ? 'primary--text'
                        : 'error--text'
                    "
                  >
                    {{ `${item.quantity}` }}
                  </span>
                </p>
                <p
                  v-if="item.can_be != 'SALE'"
                  class="grey--text font-weight-bold mb-0 caption"
                >
                  {{ `${$tc('minStock', 1)}: ` }}
                  <span
                    :class="
                      item.quantity - item.min_stock > 0
                        ? 'primary--text'
                        : 'error--text'
                    "
                  >
                    {{ `${item.min_stock}` }}
                  </span>
                </p>
              </v-col>

              <!-- Plantilla, Inventariable, tipos de cliente -->
              <v-col
                cols="12"
                :md="showMode == 'simple' ? 7 : showMode == 'list' ? 5 : 12"
              >
                <p v-if="item.template !== null" class="grey--text mb-1">
                  <span class="font-weight-black">
                    {{ `${$tc('template', 1)}: ` }}
                  </span>
                  <span class="primary--text subtitle-2">
                    {{ item.template_name }}
                  </span>
                </p>

                <p class="mt-0 grey--text font-weight-black subtitle-2">
                  {{ `${$tc(inventoriable(item.can_be))}` }}
                </p>

                <p
                  v-show="mode == 'product'"
                  cols="12"
                  :sm="showMode == 'simple' ? 8 : 12"
                  :md="showMode == 'list' ? 7 : showMode == 'simple' ? 3 : 12"
                >
                  <span
                    v-if="item.price_type === 'matrix'"
                    class="primary--text subtitle-2 font-weight-black"
                  >
                    {{ $t('matrix_prices') }}
                  </span>
                  <v-list
                    v-if="item.price_type !== 'matrix'"
                    dense
                    class="product-index-customer-types"
                  >
                    <v-list-group
                      v-if="
                        getPermissions([`product.view_productprices`]) ||
                        isAdmin
                      "
                      @click.stop="getPrices(item.pk)"
                    >
                      <v-icon slot="appendIcon" size="12">
                        fas fa-arrow-down
                      </v-icon>
                      <template v-slot:activator>
                        <v-list-item-title>
                          <span
                            class="primary--text subtitle-2 font-weight-black"
                          >
                            {{ $tc('level', 2) }}
                          </span>
                        </v-list-item-title>
                      </template>
                      <v-skeleton-loader
                        v-if="loadPrice"
                        type="image"
                        tile
                      ></v-skeleton-loader>
                      <v-list-item
                        v-show="prices[item.pk] != undefined"
                        dense
                        v-for="param in prices[item.pk]"
                        :key="'param-' + param.pk"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            class="pl-1 primary--text"
                            style="font-size: 12px; font-weight: 400"
                          >
                            <b>&middot;</b>
                            <i-money mode="text" :value="param.price">
                              <span class="font-weight-black">
                                {{ `${param.name}:` }}
                              </span>
                            </i-money>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </p>
              </v-col>

              <!-- Eliminar, favorito, costo, días de entrega -->
              <v-col
                class="d-flex flex-column justify-content-center g-1 grey--text"
                cols="12"
                :md="showMode != 'grid' ? 3 : 12"
              >
                <p class="primary--text caption text-right mb-2">
                  <v-menu
                    v-if="
                      mode == 'product' &&
                      (viewOtherBtn.add || viewOtherBtn.delete)
                    "
                    class="ml-0"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="pr-0"
                        color="primary"
                        dark
                        small
                        text
                        v-on="on"
                        @click.stop
                      >
                        {{ $t('action') }}
                        <v-icon class="ml-1 mr-3" size="10">
                          fa-chevron-down
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-if="viewOtherBtn.add">
                        <i-btn
                          classes="mx-1"
                          color="primary"
                          icon="fa-clone"
                          outlined
                          :title="$tc('clone', 1)"
                          @click.prevent="clone(item.pk)"
                        />
                      </v-list-item>
                      <v-list-item v-if="canRemove(item, viewOtherBtn, tabs)">
                        <i-btn
                          classes="mx-1"
                          color="error"
                          icon="fa-trash"
                          outlined
                          :title="$tc('delete', 1)"
                          @click.prevent="remove(item.pk)"
                        />
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-tooltip v-if="showMode !== 'grid'" bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-if="viewOtherBtn.edit"
                        v-on="on"
                        @click.stop="changeFavorite(item)"
                        small
                        icon
                        color="red"
                      >
                        <v-icon
                          small
                          color="red lighten-1"
                          v-text="
                            item.favorite ? 'fas fa-heart' : 'far fa-heart'
                          "
                        />
                      </v-btn>
                    </template>
                    <span>
                      {{
                        item.favorite
                          ? $tc('leave', 1)
                          : $t('add2', { value: $t('favorite') })
                      }}
                    </span>
                  </v-tooltip>
                </p>
                <p
                  v-show="mode == 'product' && item.price_type !== 'matrix'"
                  class="text-right mb-1"
                >
                  <span class="primary--text font-weight-black">
                    {{ `${$tc('cost', 1)}: ` }}
                  </span>
                  <i-money
                    class="primary--text"
                    mode="text"
                    :value="item.cost"
                  />
                </p>
                <p
                  v-if="
                    mode == 'cart' &&
                    type == 'sale' &&
                    item.price_type !== 'matrix'
                  "
                  class="text-right"
                >
                  <showProductPrice
                    :pkProduct="item.pk"
                    :priceSuggest="item.priceSuggest"
                    :type_customer="customer !== null ? customer.level.id : ''"
                    :customer="customer !== null ? customer.pk : ''"
                  />
                </p>
                <p
                  v-if="
                    mode == 'cart' &&
                    type == 'sale' &&
                    item.price_type == 'matrix'
                  "
                  class="text-right"
                >
                  <span class="primary--text font-weight-bold headline">
                    {{ $t('matrix_prices') }}
                  </span>
                </p>
                <p
                  v-show="mode == 'cart'"
                  class="grey--text caption text-right mb-2"
                >
                  {{ `${$t('turnaround')}: ${item.turnaround}` }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </i-card-list>
      <v-btn
        v-show="!$store.getters.getIsloading"
        id="go-to"
        bottom
        color="secondary"
        dark
        fab
        fixed
        right
        @click="$vuetify.goTo(positionScroll ? 0 : '#pagination')"
        v-scroll="onScroll"
      >
        <v-icon
          dark
          v-text="positionScroll ? 'fa-chevron-up' : 'fa-chevron-down'"
        >
        </v-icon>
      </v-btn>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import dialprodpurchase from '../../components/purchase/DialogProduct.vue'
import dialprodsale from '../../components/sale/DialogProduct.vue'
import showProductPrice from '../../components/showProductPrice/list.vue'

export default {
  name: 'cart',
  props: {
    mode: {
      type: String,
      default: 'product'
    },
    floatElement: {
      type: Boolean,
      default: false
    },
    order: {
      type: [Number, String]
    },
    orderName: {
      type: [String]
    },
    orderImage: {
      type: [File, String]
    },
    statusOrder: {
      type: String
    },
    customer: {
      type: Object
    },
    customerTax: {
      default: false,
      type: Boolean
    },
    type: {
      type: String
    }
  },
  components: {
    dialprodpurchase,
    dialprodsale,
    showProductPrice
  },
  data() {
    return {
      positionScroll: 0,
      enableSearch: false,
      loading: false,
      isLoading: false,
      cart: [],
      search: '',
      searchingMode: false,
      template: '',
      prices: {},
      loadPrice: false,
      tabs: 'tab-active',
      reload: false
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      company: 'company/getCompanyData',
      isAdmin: 'session/isAdmin'
    }),
    viewOtherBtn() {
      return {
        add:
          (this.getPermissions([`product.add_product`]) &&
            this.getPermissions([`product.view_producttemplate`]) &&
            this.getPermissions([`base_config.view_productiondepartment`])) ||
          this.isAdmin,
        edit:
          (this.getPermissions([`product.change_product`]) &&
            this.getPermissions([`product.view_producttemplate`]) &&
            this.getPermissions([`base_config.view_productiondepartment`])) ||
          this.isAdmin,
        delete: this.getPermissions([`product.delete_product`]) || this.isAdmin
      }
    }
  },
  methods: {
    inventoriable(can_be) {
      return can_be != 'SALE' ? 'inventoriable' : ''
    },
    changeRoute(item) {
      this.$router.push({
        name: 'product-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    dialSubOrder(item, type) {
      if (type !== 'sale') {
        this.$refs.dialprodpurchase.open(item)
      } else {
        this.$refs.dialprodsale.open(item)
        // Antes verificaba si no habia en inventario
        // if (item.quantity - item.min_stock > 0) {
        //   this.$refs.dialprodsale.open(item)
        // } else {
        //   this.$toast.error(`${this.$tc('increaseStock', 1)}`)
        //   return
        // }
      }
    },
    async clone(pk) {
      const response = await this.$api.product.show({
        pk: pk,
        opt: { params: { clone: true } }
      })
      this.changeRoute({ pk: response.data.pk })
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.positionScroll = top > 60
    },
    async getPrices(pk) {
      if (this.prices[pk] == undefined) {
        this.loadPrice = true
        try {
          const response = await this.$api.product.prices.list({
            opt: {
              params: {
                mode: 'product',
                product: pk,
                company_id: this.company.pk
              }
            }
          })
          this.$set(this.prices, pk, response.data)
        } finally {
          this.loadPrice = false
        }
      }
    },
    changeFavorite(item) {
      item.favorite = !item.favorite
      this.$api.product.favorite({
        pk: item.pk,
        form: item
      })
    },
    /**
     * validateIsAdmin
     * Método para calcular la cantidad de subitems
     *
     * @return {function}
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    validateIsAdmin(event) {
      if (this.mode === 'product') {
        if (this.isAdmin && this.tabs === 'tab-models') {
          this.changeRoute({ pk: event.pk })
        }
        if (this.tabs !== 'tab-models') {
          this.changeRoute({ pk: event.pk })
        }
      } else {
        this.dialSubOrder(event, this.type)
      }
    },
    /**
     * tabStatus
     * Método determinar si la consulta es activa, inactiva o ninguna de ambas
     * para que no se mande como argumento en la consulta
     *
     * @return bool, null
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    tabStatus(status) {
      if (status === 'tab-active') return true
      if (status === 'tab-inactive') return false
      return null
    },
    /**
     * canRemove
     * Método determinar si se muestra o no el botón de borrar
     *
     * @return bool, null
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    canRemove(item, viewOtherBtn, tabs) {
      if (item.canDelete && viewOtherBtn.delete && tabs !== 'tab-models') {
        return true
      }
      if (tabs === 'tab-models' && this.isAdmin) {
        return true
      }
      return false
    }
  },
  watch: {
    floatElement: {
      immediate: true,
      handler() {}
    },
    '$vuetify.breakpoint.xsOnly': {
      handler(val) {
        if (val) {
          this.showMode = 'list'
        }
      }
    }
  }
}
</script>
<style lang="sass">
.product-index
  &-customer-types
    &.v-list
      background-color: transparent
      padding: 0
      .v-list-group
        .v-list-item
          padding: 0 10px
        .v-list-item--link:before
          border-radius: 8px
</style>
